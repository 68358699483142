<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">certificaciones</span>
    </template>
    <CertificationsTable />
  </BaseCard>
</template>

<script>
import CertificationsTable from '@/components/certifications/table/CertificationsListTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, CertificationsTable },
}
</script>

<style scoped>

</style>
