import { render, staticRenderFns } from "./CertificationsListTable.vue?vue&type=template&id=76c11112&scoped=true&"
import script from "./CertificationsListTable.vue?vue&type=script&lang=js&"
export * from "./CertificationsListTable.vue?vue&type=script&lang=js&"
import style0 from "./CertificationsListTable.vue?vue&type=style&index=0&id=76c11112&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c11112",
  null
  
)

export default component.exports